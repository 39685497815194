<template>
  <div class="m">
    <newTitle txt="Travel Tips"></newTitle>
    <div class="strategy-main">
      <div
        class="strategy-item"
        v-for="(item, index) in strategyList"
        :key="index"
        @click="itemBtnOk(item.id)"
      >
        <img
          class="strategy-img"
          v-if="item.cover_picture"
          :src="item.cover_picture"
          alt=""
        />
        <div class="strategy-txt-box">
          <div class="strategy-name">{{ item.title }}</div>
          <div class="strategy-txt" v-if="item.descript">
            {{ item.descript }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductStrategy } from "@/api/hfIndex";
import newTitle from "./Component_title.vue";
export default {
  components: { newTitle },
  data() {
    return {
      id: "",
      strategyList: [],
      lanListPam: {
        zh: "sc",
        zh_tw: "tc",
        en: "en",
        ja: "jp",
        ko: "kr",
      },
    };
  },
  activated() {
    this.id = this.$route.query.id;
    this.getProductStrategyFn(this.id);
  },
  methods: {
    itemBtnOk(id){
      // console.log('item点击事件');
      this.$router.push(`/TravelTips_detail?id=${id}`);
    },
    async getProductStrategyFn(id) {
      console.log({ id, lang: this.lanPam });
      let res = await getProductStrategy({ id, lang: this.lanPam });
      console.log(res);
      this.strategyList = res.data.data.list;
    },
  },
  computed: {
    lanPam() {
      return this.lanListPam[localStorage.getItem("locale")] || "tc";
    },
  },
};
</script>

<style lang="scss" scoped>
.m {
  height: 100vh;
  max-width: 766px;
  margin: 0 auto;
}
.strategy-main {
  padding: 14px 16px;

  .strategy-item {
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-bottom: 0;
    }

    .strategy-img {
      background-color: #efefef;
      width: 100%;
    }

    .strategy-txt-box {
      padding: 12px;
      .strategy-name {
        font-weight: 600;
        font-size: 18px;
        color: #141414;
        line-height: 22px;
      }
      .strategy-txt {
        font-weight: 400;
        font-size: 14px;
        color: #8c8c8c;
        margin-top: 8px;
        line-height: 18px;
      }
    }
  }
}
</style>
